import { Application } from '@hotwired/stimulus'
import Dropdown from 'stimulus-dropdown'
import { analyticsControllerFactory } from 'common/controllers/analytics_controller'
import PosthogAnalytics from 'common/controllers/posthog_analytics_controller'
import FormController from 'common/controllers/form_controller'
import FormImageUploaderController from 'common/controllers/form_image_uploader_controller'
import CloseModalOnLoadController from 'common/controllers/close_modal_on_load_controller'
import StudioCountdownController from 'common/controllers/studio_countdown_controller'
import LiveChatController from 'common/controllers/live_chat_controller'
import PostCommentController from 'common/controllers/post_comment_controller'
import UsersLikedController from 'common/controllers/users_liked_controller'
import UserMentionsController from 'common/controllers/user_mentions_controller'
import AttachVideoController from 'common/controllers/attach_video_controller'
import CookiesManagerController from 'common/controllers/cookies_manager_controller'
import TimeStampController from 'common/controllers/timestamp_controller'
import StickyDetectorController from 'common/controllers/sticky_detector_controller'
import { registerControllers } from 'stimulus-vite-helpers'
import { StreamActions } from 'common/streams/stream_actions'
import ValidateUrlInputController from 'common/controllers/validate_url_input_controller'
import ConfettiController from 'common/controllers/confetti_controller'
import SwitchTabsController from 'common/controllers/switch_tabs_controller'

import '@hotwired/turbo-rails'

console.log('REGISTER CONTROLLERS!')
window.Turbo.session.drive = false

window.Stimulus = Application.start()
window.Stimulus.debug = process.env.NODE_ENV === 'development'

const controllers = import.meta.glob('./controllers/**/*_controller.js', { eager: true })

registerControllers(window.Stimulus, controllers)

window.Stimulus.register('analytics', analyticsControllerFactory('admin'))
window.Stimulus.register('posthog-analytics', PosthogAnalytics)
window.Stimulus.register('dropdown', Dropdown)
window.Stimulus.register('form', FormController)
window.Stimulus.register('form-image-uploader', FormImageUploaderController)
window.Stimulus.register('close-modal-on-load', CloseModalOnLoadController)
window.Stimulus.register('live-chat', LiveChatController)
window.Stimulus.register('post-comment', PostCommentController)
window.Stimulus.register('users-liked', UsersLikedController)
window.Stimulus.register('user-mentions', UserMentionsController)
window.Stimulus.register('attach-video', AttachVideoController)
window.Stimulus.register('cookies-manager', CookiesManagerController)
window.Stimulus.register('timestamp', TimeStampController)
window.Stimulus.register('studio-countdown', StudioCountdownController)
window.Stimulus.register('validate-url-input', ValidateUrlInputController)
window.Stimulus.register('confetti', ConfettiController)
window.Stimulus.register('switch-tabs', SwitchTabsController)
window.Stimulus.register('sticky-detector', StickyDetectorController)

// Custom turbo-stream actions here
document.addEventListener('turbo:before-stream-render', event => {
  const stream = event.target

  if (StreamActions[stream.action]) {
    event.preventDefault()
    StreamActions[stream.action](stream)
  }
})

document.addEventListener('turbo:frame-missing', event => {
  const location = event.detail.response.url
  event.detail.visit(location, { frame: '_top' })
})
