import { Controller } from '@hotwired/stimulus'
import Papa from 'papaparse'

export default class extends Controller {
  static targets = [
    'area', 'fileName', 'fileInput', 'fileInfo',
    'recordsCount', 'userDataContainer', 'singleUserField', 'singleUserText'
  ]

  connect() {
    this.fileInputTarget.classList.remove('hidden')
    this.fileInfoTarget.classList.add('hidden')
  }

  setDragClass(isOver) {
    const button = this.areaTarget.querySelector('ds-button')
    if (isOver) {
      this.areaTarget.classList.add('bg-white', 'ring-1')
      button.hidden = true
    } else {
      this.areaTarget.classList.remove('bg-white', 'ring-1')
      button.hidden = false
    }
  }

  onDrag(e) {
    const isDragOver = e.type === 'dragover'
    if (isDragOver) e.preventDefault()
    this.setDragClass(isDragOver)
  }

  onSelect(event) {
    event.preventDefault()
    this.onDrag(event)
    const files = event.target.files || event.dataTransfer.files

    if (!files || files.length === 0) {
      return
    }

    this.fileInputTarget.classList.add('hidden')
    this.fileInfoTarget.classList.remove('hidden')

    const file = files[0]
    this.fileNameTarget.textContent = file.name

    const users = []
    const container = this.targets.find('userDataContainer')

    Papa.parse(file, {
      delimiter: ',',
      skipEmptyLines: true,
      transform: value => value.trim(),
      complete: results => {
        if (results.data && results.data.length > 0) {
          results.data.forEach(row => {
            if (row.length >= 2 && row[0] && row[1]) {
              container.appendChild(this.createInput('users[][name]', row[0]))
              container.appendChild(this.createInput('users[][email]', row[1]))

              users.push({ name: row[0], email: row[1] })
            }
          })

          this.recordsCountTarget.textContent = users.length
          this.toggleSingleUserFields(false)
        }
      },
      error: err => { console.log(err) },
    })
  }

  deleteFile() {
    this.toggleSingleUserFields(true)

    this.fileInputTarget.classList.remove('hidden')
    this.fileInfoTarget.classList.add('hidden')
    this.fileInputTarget.value = ''
    this.targets.find('userDataContainer').innerHTML = ''
  }

  toggleSingleUserFields(enabled) {
    // eslint-disable-next-line no-param-reassign
    this.targets.findAll('singleUserField').forEach(e => { e.disabled = !enabled })
    this.targets.findAll('singleUserText').forEach(e => {
      if (enabled) {
        e.classList.remove('text-gray-600')
        e.classList.add('text-gray-900')
      } else {
        e.classList.remove('text-gray-900')
        e.classList.add('text-gray-600')
      }
    })
  }

  createInput(name, value) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    return input
  }
}
