import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  static values = {
    clearAfter: { type: Number, default: 3000 },
  }

  connect() {
    this.clear()
  }

  clear() {
    const content = this.hasContentTarget ? this.contentTarget : this.element
    if (content) {
      setTimeout(() => {
        content.remove()
      }, this.clearAfterValue)
    }
  }
}
