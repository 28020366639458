import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'mainCurrencyInput',
    'localizedCurrencyInput',
    'frameLoader',
    'recalculateButton'
  ]

  static values = {
    calculateLocalizedPricesUrl: String
  }

  initialized = false

  async calculateLocalizedPrices() {
    const value = +this.mainCurrencyInputTarget.value
    if (!value) return

    if (this.hasFrameLoaderTarget) {
      this.frameLoaderTarget.showLoader()
    }

    const result = await new FetchRequest('POST', '/bullet/api/exchange_currency', {
      body: JSON.stringify({
        value
      })
    }).perform().then(response => response.json)

    this.localizedCurrencyInputTargets.forEach(input => {
      input.value = result[input.dataset.currency]
      input.disabled = false
      const tooltipContent = input.parentElement.querySelector('div[slot="content"]')
      if (tooltipContent) {
        tooltipContent.classList.add('hidden')
      }
    })

    if (this.hasFrameLoaderTarget) {
      this.frameLoaderTarget.hideLoader()
    }
  }

  async onMainCurrencyInputChange(event) {
    const value = +this.mainCurrencyInputTarget.value
    if (!value) {
      this.setRecalculateButtonDisabledState(true)
      return
    }

    this.setRecalculateButtonDisabledState(false)

    if (!event.params?.recalculateOnChange) return

    if (this.initialized) return

    await this.calculateLocalizedPrices()

    this.initialized = true

    this.showRecalculateButton()
  }

  setRecalculateButtonDisabledState(disabled) {
    if (this.hasRecalculateButtonTarget) {
      this.recalculateButtonTarget.disabled = disabled
    }
  }

  showRecalculateButton() {
    if (this.hasRecalculateButtonTarget) {
      this.recalculateButtonTarget.classList.remove('hidden')
      const { parentElement } = this.recalculateButtonTarget
      if (parentElement.nodeName === 'DS-TOOLTIP') {
        parentElement.classList.remove('hidden')
      }
    }
  }
}
