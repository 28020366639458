import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.onInputChange)
  }

  disconnect() {
    this.element.removeEventListener('change', this.onInputChange)
  }

  onInputChange = () => {
    const { value } = this.element
    if (!value) return

    this.element.value = Number(value).toFixed(2)
  }
}
