import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['uploader', 'list', 'tooltip']

  static values = { createUrl: String }

  connect() {
    this.uploadedSuccess = this.uploadedSuccess.bind(this)
    this.uploaderTarget.addEventListener('success', this.uploadedSuccess)
    this.observer = new MutationObserver(this.validateMaxUploadedAudioTracksLimit)
    this.observer.observe(this.listTarget, { childList: true })
  }

  uploadedSuccess(event) {
    event.detail.forEach(({ name, uploadURL: source_url }) => {
      new FetchRequest('post', this.createUrlValue, {
        body: JSON.stringify({
          videos_audio_track: { name, source_url }
        })
      }).perform()
    })
  }

  validateMaxUploadedAudioTracksLimit = () => {
    if (this.listTarget.children.length >= 6) {
      this.tooltipTarget.removeAttribute('disabled')
      this.uploaderTarget.setAttribute('disabled', 'disabled')
    } else {
      this.tooltipTarget.setAttribute('disabled', 'disabled')
      this.uploaderTarget.removeAttribute('disabled')
    }
  }

  showDestroyConfirmationModal(el) {
    new FetchRequest('delete', el.currentTarget.dataset.url).perform()
  }

  showEditingModal(el) {
    new FetchRequest('get', el.currentTarget.dataset.url).perform()
  }
}
