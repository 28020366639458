import { Controller } from '@hotwired/stimulus'

const segmentsObjToArray = segmentsAsObj => Object
  .entries(segmentsAsObj)
  .filter(([, val]) => val)
  .map(([key]) => key)

const buildSegmentsAsObj = segments => segments.reduce((acc, s) => {
  acc[s] = true
  return acc
}, {})

const buildState = (oldState, newState) => {
  // toggle all on
  if (!oldState.all_members && newState.all_members) {
    return {
      ...newState,
      active: true,
      churned: true
    }
  }

  // toggle all off
  if (oldState.all_members && !newState.all_members) {
    return {
      ...newState,
      active: false,
      churned: false
    }
  }

  // all selected
  if (newState.active && newState.churned) {
    return { ...newState, all_members: true }
  }

  // some unselected
  if (!newState.active || !newState.churned) {
    return {
      ...newState,
      all_members: false
    }
  }

  return newState
}

export default class extends Controller {
  static targets = ['segments', 'allMembersCheckbox', 'specificSegmentsCheckbox']

  connect() {
    this.state = buildSegmentsAsObj(this.element.value)
  }

  change(event) {
    this.state = buildState(this.state, buildSegmentsAsObj(event.target.value))

    event.target.value = segmentsObjToArray(this.state)
  }
}
